<template>
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="出人库单号:">
                        <el-input v-model="search.ticketNo" clearable placeholder="精确搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="仓库所属用户账号:">
                        <el-input v-model="search.rentUserPhone" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="类型:">
                        <el-select v-model="search.bizType" placeholder="" style="width: 160px;">
                            <el-option
                                v-for="item in bizTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="商品名称:">
                        <el-input v-model="search.goodsName" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                    </el-form-item>
                    <el-form-item label="出人库人员:">
                        <el-input v-model="search.operationUserName" clearable placeholder="精确搜索" style="width: 160px;"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="medium" @click="inquire">查询</el-button>
                        <el-button type="primary" size="medium" @click="reset">重置</el-button>
                    </el-form-item>

                </el-form>
            </template>

            <template v-slot:table="row">
                <el-table v-if="row.height"  :height="row.heightText"  :data="tableData" style="width: 100%">
                    <el-table-column type="index" width="50" label="序号" />
                    <el-table-column prop="ticketNo" label="出入库单号" width="180" />
                    <el-table-column prop="rentUserLogo" label="仓库所属用户" width="180" align="center">
                        <template #default="scope">
                            <el-image class="avatar" fit="contain" :src="scope.row.rentUserLogo || 'http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png'" style="width: 30px;height: 30px;" />
                            {{ scope.row.rentUserPhone }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="bizType" label="类型" show-overflow-tooltip  >
                        <template #default="scope">
                            {{ scope.row.type == 1 ? '入库-': scope.row.type == 2 ? '出库-':'' }}
                            <!-- {{ bizTypeList[scope.row.bizType - 1].label }} -->
                            {{ scope.row.remark | remarkModify }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="count" label="出入库重量(KG)" width="180" />

                    <el-table-column prop="goodsName" label="商品名称" width="160" />
                    <el-table-column prop="operationUserName" label="出入库人员" width="180" align="center">
                        <template #default="scope">
                            <el-image class="avatar" fit="contain" :src="scope.row.operationUserLogo || 'http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png'" style="width: 30px;height: 30px;" />
                            {{ scope.row.operationUserName }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" width="120" />
                    <el-table-column prop="createTime" label="出入库时间" />

                </el-table>
            </template>

            <template slot="after">
                <div class="paging-row">
                    <el-pagination
                        v-if="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </template>
        </table-height-auto>
    </div>
</template>

<script>
import {URL} from '../../../config'

export default {
    name: 'warehouse',
    filters: {
        remarkModify(data) {
            return data.split('：')[0]
        },
    },
    data() {
        return {
            search: {
                ticketNo: '',
                rentUserPhone: '',
                bizType: '',
                goodsName: '',
                operationUserName: '',
            },
            loading: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
            bizTypeList: [
                { label: '全部', value: '' },
                { label: '农场收获', value: 1 },
                { label: '捐赠公益组织', value: 5 },
                { label: '帮卖', value: 3 },
                { label: '回收', value: 4 },
                { label: '邮寄', value: 2 },
            ],
        }
    },
    methods: {
        getList() {
            let type = '';
            let bizType = '';
            if(this.search.bizType) {
                type = this.search.bizType > 1 ? 2 : 1;
                bizType = this.search.bizType == 5 ? 1 : this.search.bizType;
            }
            this.axios.post(URL.farm.farmStoreInoutDetailPage, {...this.search, pageNo: this.currentPage, pageSize: this.pageSize, bizType, type }).then(res=>{
                console.log(res);
                if(res.code === '0'){
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                }else{
                    this.$message.error(res.msg);
                }
            })
        },

        inquire() {
            this.currentPage = 1;
            this.getList();
        },

        reset () {
            this.search = {
                ticketNo: '',
                rentUserPhone: '',
                bizType: '',
                goodsName: '',
                operationUserName: '',
            }
            this.getList()
        },

        handleSizeChange(val,type){
            this.pageSize = val;
            this.currentPage = 1;
            this.getList()
        },

        handleCurrentChange(val,type) {
            this.currentPage = val;
            this.getList();
        },

    },
    mounted() {
        this.getList();
    },

}
</script>

<style lang="less" scoped>
.pages {
    .search {
        display: flex;
        justify-content: space-between;

    }

}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>
